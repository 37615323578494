.controls {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
}

.controls .control .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 12px;
    background: var(--colorWhite);
    color: var(--colorBlack);
    font-size: 1rem;
    border-radius: 4px;
    border: none;
}

.controls .control .btn svg {
    margin-right: 0.5rem;
}

.controls .control .btn:hover,
.controls .btn:focus {
    cursor: pointer;
}

.control {
    display: flex;
    flex-direction: column;
}

.control.segmented {
    flex-direction: row;
    gap: 1rem;
}
