:root {
  --colorWhite: #fefae0;
  --colorBlack: #03012D;
  --colorGreen: #b9fa4c;
}

.main {
  display: flex;
  height: 95vh;
  padding: 0 1rem;
  align-items: center;
  flex-direction: column;
  color: var(--colorWhite);
}

.contact {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 5vh;
  padding: 0 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 375px;
  width: 100%;
  margin-top: 20vh;
}

@media only screen and (max-width: 400px)  {
  .container {
    max-width: 330px;
  }
}

@media only screen and (max-height: 850px)  {
  .main {
    justify-content: center;
  }
  .container {
    margin-top: 0;
  }
}

.logo {
  pointer-events: none;
}

.title {
  font-family: 'Quicksand', sans-serif;
}

.information-placeholder p { 
  margin: 0;
}

.u-fit-content {
  width: fit-content;
}

.u-align-self-flex-end {
  align-self: flex-end;
}

.u-text-align-center {
  text-align: center;
}

.u-margin-vertical-small {
  margin: 2rem 0;
}

.u-margin-bottom-small {
  margin-bottom: 2rem;
}

.u-margin-vertical-smaller {
  margin: 1rem 0;
}
