body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--colorBlack);
}

p {
  margin: 0;
}

h1 {
  margin: 1rem 0;
}

a {
  color: var(--colorWhite);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}