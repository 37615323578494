.text-button {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 1px dashed #fff;
  color: inherit;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

.text-button:hover {
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
